import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './pages/Home/Home';
import Blog from './pages/Blog/Blog';

import Team from './pages/Team/Team';
import Careers from './pages/Careers/Careers';
import NotFound from './components/Notfound/NotFound';
import Products from './pages/Product/Products';

const routers = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <Home /> },
            { path: 'blog', element: <Blog /> },
            { path: 'product', element: <Products /> },
            { path: 'team', element: <Team /> },
            { path: 'careers', element: <Careers /> },
            { path: '*', element: <NotFound /> },
        ],
    },
]);
function App() {
    return <RouterProvider router={routers} />;
}

export default App;
