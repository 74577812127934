import React from 'react';


interface MediaCardProps {
    imgSrc : string ,
    heading : string, 
    paragraphs : string[],
    reverse? : boolean
}

export default function MediaCard({imgSrc , heading , paragraphs , reverse} : MediaCardProps) {
    return (
        <div className="container py-4 pt-5">
            <div className="row align-items-center gy-3">
                <div className="col-lg-6 order-last order-lg-0 ">
                    <div className="p-2">
                    <img src={imgSrc} alt="swords" width="100%" />
                    </div>
                </div>
                <div className={`col-lg-6 ${reverse && 'order-lg-first'}`}>
                    <div className="p-lg-5 p-3">
                        <h2 className="heading">{heading}</h2>

                        {paragraphs.map((p, i) => <p className='text' key={i}>{p} </p> )}
                    </div>
                </div>
            </div>
        </div>
    );
}
