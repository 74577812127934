
import { Link } from 'react-router-dom';

import './productItem.scss';

interface ProductItemProps {
    imgSrc : string ,
    heading : string ,
    detailsLink ?: string,
    linkContent ? : string
}

export default function ProductItem({imgSrc , heading , detailsLink , linkContent} : ProductItemProps) {
  return (
    <div className='product-item py-5 px-4 text-center'>
        <img src={`/images/products/${imgSrc}.svg`} className="product-item-img" alt="" />
        <h3 className='my-3 heading-sm'>{heading}</h3>
        
        { detailsLink ? <a href={detailsLink} className='link-black' target='_blank'>Learn More</a> : <a className='link-black link-disabled'>Coming 2023</a> }
    </div>
  )
}
