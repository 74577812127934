

export default function Hero() {
    return (
        <>
            <div className="container-lg p-4">
                <div className="header ">
                    <div className="row flex-column justify-content-center h-100">
                        <div className="col-12">
                            <div className="position-relative">
                                <img
                                    className="hero-img"
                                    src="/images/hero/hero.png"
                                    alt="hero"
                                    width="100%"
                                />
                            </div>
                        </div>
                        <div className="col-xl-7 order-first order-xl-0">
                            <h1 className="main-heading pb-2">
                                Where Blockchain Meets{' '}
                                <span className="highlight">AAA</span> Crypto
                                Gaming
                            </h1>
                            <p className="text-lg mt-4 pe-lg-5">
                                Our goal is to shape the future of entertainment by creating AAA-quality crypto games for the Web3 era.
                            </p>
                        </div>

                        <div className="col-xl-7 mt-lg-5">
                            <div className="pt-5">
                                <p className="text-muted">Partners:</p>
                                <div className="d-flex flex-wrap align-items-center partners gap-4">
                                    <img
                                        src="/images/hero/cronos.svg"
                                        alt="partners"

                                    />
                                    <img
                                        src="/images/hero/chainplay.svg"
                                        alt="partners"
                                        
                                    />
                                    <img
                                        src="/images/hero/polygon.svg"
                                        alt="partners"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5 container-lg ">
                
                <p className="text-xxl my-4 px-3 px-lg-0 ">
                    <span className="fw-bold">
                    The Legends of Bezogia merges the worlds of cryptocurrency and gaming,
                    </span>{' '}
                    seamlessly intertwining them to create a revolutionary experience that is light-years ahead of the competition. We're not just talking the talk, we're walking the walk... and having so much fun, we're practically dancing.
                </p>
            </div>
        </>
    );
}
