import React from 'react';
import { Scrollbar, Navigation } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import useMediaQuery from '../../hooks/useMediaQuery';
import HeaderHero from './HeaderHero';

import 'swiper/css';
import 'swiper/css/scrollbar';

const teamData = [
    {
        imgSrc: 'NickVadera',
        name: 'Nick Vadera',
        job: ' CEO & CO-founder',
    },
    {
        imgSrc: 'Zam',
        name: 'Zamir Kakar',
        job: 'Chief Operating Officer',
    },
    {
        imgSrc: 'AhmedZakaria',
        name: 'Ahmed Zakaria',
        job: 'Chief Advisor',
    },
    // {
    // imgSrc: 'AhmadSukkar',
    //  name: 'Ahmad',
    //    job: 'Lead Web dev',
    //  }, 
];
const dreamTeam = [
    {
        imgSrc: 'MarinaYorke.png',
        name: 'Marina Yorke',
        Job: 'Executive Assistant'
    },
    {
        imgSrc: 'Ali.png',
        name: 'Ali',
        Job: 'Lead Game Producer',
    },
    {
        imgSrc: 'MikeZhao.png',
        name: 'Mike Zhao',
        Job: 'Office Manager'
    },
    {
        imgSrc: 'JahanzaibImran.png',
        name: 'Jahanzaib Imran',
        Job: 'Full Stack Developer'
    },
    {
        imgSrc: 'Honda.png',
        name: 'Honda',
        Job: 'Full Stack Developer'
    },
    {
        imgSrc: 'Waqar.png',
        name: 'Muhammad Waqar',
        Job: 'Sr Full Stack Developer'
    },
    {
        imgSrc: 'AbdulWahab.png',
        name: 'Abdul Wahab',
        Job: 'Unity Developer'
    },
    {
        imgSrc: 'YongZhang.png',
        name: 'Yong Zhang',
        Job: 'Blockchain Developer'
    },
    {
        imgSrc: 'SquallSu.png',
        name: 'Squall Su',
        Job: 'Battle Planner'
    },
    {
        imgSrc: 'EvaMeng.png',
        name: 'Eva Meng',
        Job: 'Sr Animator'
    },
    {
        imgSrc: 'FoxYe.png',
        name: 'Fox Ye',
        Job: 'Sr Unity Developer'
    },
    {
        imgSrc: 'SunnyHe.png',
        name: 'Sunng He',
        Job: 'Sr Unity Developer'
    },
    {
        imgSrc: 'VayneJin.png',
        name: 'Vayne Jin',
        Job: 'Unity Developer'
    },
    {
        imgSrc: 'RockQiu.png',
        name: 'Rock Qiu',
        Job: 'Unity Developer'
    },
    {
        imgSrc: 'AsuYang.png',
        name: 'Asu Yang',
        Job: '3D Designer'
    },
    {
        imgSrc: 'AnthonyLiu.png',
        name: 'Anthony Liu',
        Job: '3D Designer'
    },
    {
        imgSrc: 'KevinHu.png',
        name: 'Kevin Hu',
        Job: '3D Animator'
    },
    {
        imgSrc: 'AnneXu.png',
        name: 'Anne Xu',
        Job: 'UI Designer'
    },
    {
        imgSrc: 'LorianLiMingfu.png',
        name: 'Lorian Li Mingfu',
        Job: '2D Concept Artist'
    },
]

const globalHeadcount = [
    {
        count: 25,
        country: 'China',
        tech: ['Game Development'],
        gredient: '(225deg, #6A55FE 0%, #513CE9 100%)',
    },
    {
        count: 12,
        country: 'Pakistan',
        tech: ['Blockchain Development', 'Marketing'],
        gredient: '(223.43deg, #37E7C0 -2.89%, #00BA91 100%), #3FF0C9',
    },
    {
        count: 4,
        country: 'Dubai',
        tech: ['Global HQ', 'Business Development', 'Marketing'],
        gredient: '(225deg, #FF6F31 0%, #E34400 100%)',
    },
    /*{
         count: 5,
         country: 'Switzerland',
         tech: ['Game Development'],
         gredient: '(225deg, #EC1B6C 0%, #D70053 100%)',
     },*/
    {
        count: 14,
        country: 'Remote',
        tech: ['Marketing', 'Copywriting', 'Content Creation'],
        gredient: '(225deg, #42CF50 0%, #00B512 100%)',
    },
];

export default function Team() {
    const swiper = useSwiper();
    const smScreen = useMediaQuery('(max-width: 768px)');
    return (
        <>
            <HeaderHero />
            <div className="container py-4">
                <h2 className="heading text-center pt-2 my-5">
                    Executive Team
                </h2>
                <div className="row align-items-center gy-3 pb-4">
                    {teamData.map((member, i) => (
                        <div key={i} className="col-md-6 col-lg-4 h-100">
                            <div className="member text-center ">
                                <div className="member-img mx-auto bg-white">
                                    <img
                                    height={280}
                                        src={`/images/team/${member.imgSrc}.jpeg`}
                                        alt="team"
                                        className={
                                            'w-100 ' +
                                            (member.imgSrc === 'Ali' &&
                                                '')
                                        }
                                    />
                                </div>
                                <h3 className="heading-sm mt-4">
                                    {member.name}
                                </h3>
                                <p>{member.job}</p>
                            </div>
                        </div>
                    ))}
                    <h2 className='heading text-center pt-2 my-5'>
                        Dream Team
                    </h2>

                    {dreamTeam.map((member, i) => (
                        <div key={i} className="col-md-6 col-lg-4 col-xl-3 h-100">
                            <div className="member text-center ">
                                <div className="member-img mx-auto bg-white w-[400px]">
                                    <img src={`/images/team/${member.imgSrc}`} className='w-100' alt='DreamTeam' />
                                </div>
                                <h3 className="heading-sm mt-4">
                                    {member.name}
                                </h3>
                                <p>{member.Job}</p>
                            </div>
                        </div>
                    ))}


                    {/* <div className="col-md-6 col-lg-4">
                        <div className="member text-center h-100">
                            <div className="member-img mx-auto bg-white">
                                <img
                                    src={'/images/team/Sukkar1.png'}
                                    alt="team"
                                    className={
                                        'w-100 '
                                    }
                                />
                            </div>
                            <h3 className="heading-sm mt-4">
                                {'Ahmad Sukkar'}
                            </h3>
                            <p>{'Web Development Team Lead'}</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="container-fluid">
                <div className="bg-14 p-lg-5 p-3 radius-60 my-5">
                    <h2 className="heading text-center py-4">
                        Global Team Headcount
                    </h2>

                    {/* * *************************** */}
                    {
                        smScreen && (
                            <Swiper
                                navigation={{
                                    nextEl: '.image-swiper-button-next',
                                    prevEl: '.image-swiper-button-prev',
                                    disabledClass: 'swiper-button-disabled',
                                }}
                                modules={[Navigation, Scrollbar]}
                                scrollbar={{
                                    hide: false,
                                }}
                                slidesPerView={1}
                                centeredSlides={true}
                                grabCursor={true}
                                spaceBetween={45}
                                className="bezogia-slider headcount-slide"
                            >
                                {globalHeadcount.map((headcount, i) => (
                                    <SwiperSlide key={i} className="d-flex justify-content-center align-items-center">
                                        <div className="text-center">
                                            <div
                                                style={{
                                                    background: `linear-gradient${headcount.gredient}`,
                                                }}
                                                className="radius-20 py-4 px-3 d-inline-block position-relative"
                                            >
                                                <p className="text-xl mb-0">
                                                    {headcount.count}+
                                                </p>
                                                <div className="number-usrs">
                                                    <img
                                                        src="/images/team/users.png"
                                                        alt="usrs"
                                                        className=" "
                                                    />
                                                </div>
                                            </div>
                                            <h4 className="heading-sm pt-4">
                                                {headcount.country}
                                            </h4>
                                            <ul className="list-unstyled text-muted">
                                                {headcount.tech.map((techno, i) => (
                                                    <li key={i} className="pt-1">
                                                        {techno}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                <div className="d-md-none d-block ">
                                    <button
                                        className="image-swiper-button-prev btn-swiper headcount-prev"
                                        onClick={() => swiper.slidePrev()}
                                    >
                                        <i className="fa-solid fa-less-than"></i>
                                    </button>
                                    <button
                                        className="image-swiper-button-next btn-swiper headcount-next"
                                        onClick={() => swiper.slideNext()}
                                    >
                                        <i className="fa-solid fa-greater-than"></i>
                                    </button>
                                </div>
                            </Swiper>
                        )
                    }
                    {/* * *************************** */}

                    {
                        !smScreen && (
                            <div className="container">
                                <div className="row justify-content-center gy-3">
                                    {globalHeadcount.map((headcount, i) => (
                                        <div key={i} className="col-md-4 col-xl">
                                            <div className="text-center">
                                                <div
                                                    style={{
                                                        background: `linear-gradient${headcount.gredient}`,
                                                    }}
                                                    className="radius-20 py-4 px-3 d-inline-block position-relative"
                                                >
                                                    <p className="text-xl mb-0">
                                                        {headcount.count}+
                                                    </p>
                                                    <img
                                                        src="/images/team/users.png"
                                                        alt="usrs"
                                                        className="number-usrs "
                                                    />
                                                </div>
                                                <h4 className="heading-sm pt-4">
                                                    {headcount.country}
                                                </h4>
                                                <ul className="list-unstyled text-muted">
                                                    {headcount.tech.map((techno, i) => (
                                                        <li key={i} className="pt-1">
                                                            {techno}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    }
                    <div className="py-4">
                        <Swiper
                            navigation={{
                                nextEl: '.image-swiper-button-next',
                                prevEl: '.image-swiper-button-prev',
                                disabledClass: 'swiper-button-disabled',
                            }}
                            modules={[Navigation, Scrollbar]}
                            scrollbar={{
                                hide: false,
                            }}
                            slidesPerView={'auto'}
                            centeredSlides={true}
                            grabCursor={true}
                            breakpoints={{
                                768: {
                                    centeredSlides: true,
                                    initialSlide: 2,
                                },
                            }}
                            spaceBetween={45}
                            className="bezogia-slider team-slider"
                        >
                            <SwiperSlide className="pb-4 p-md-0">
                                <img
                                    src="/images/team/team00.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide className="pb-4 p-md-0">
                                <img
                                    src="/images/team/team01.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide className="pb-4 p-md-0">
                                <img
                                    src="/images/team/team02.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide className="pb-4 p-md-0">
                                <img
                                    src="/images/team/team03.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>

                            <div className="d-none d-md-block">
                                <button
                                    className="image-swiper-button-prev btn-swiper"
                                    onClick={() => swiper.slidePrev()}
                                >
                                    <i className="fa-solid fa-less-than"></i>
                                </button>
                                <button
                                    className="image-swiper-button-next btn-swiper"
                                    onClick={() => swiper.slideNext()}
                                >
                                    <i className="fa-solid fa-greater-than"></i>
                                </button>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="learn-more p-md-5 p-4 my-5 d-flex flex-column flex-xl-row align-items-center justify-content-between">
                    <h3 className="heading text-center mb-0 mx-3 py-3">
                        Ready To Learn More?
                    </h3>
                    <a className="btn-green border-0 mx-3 px-md-5 px-4 my-3" href='https://www.linkedin.com/company/zogi-labs/jobs/' target='_blank'>
                        See Career Opportunities
                    </a>
                </div>
            </div>
        </>
    );
}
