import React from 'react';
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import useMediaQuery from '../../hooks/useMediaQuery';

import 'swiper/css';
import 'swiper/css/scrollbar';

const ProductSwiper : React.FC<any> = ({children ,items ,swiperClass, heading  }) => {
    const smScreen = useMediaQuery('(max-width: 767px)');
    const xlScreen = useMediaQuery('(min-width: 1400px)');
    var swiper = useSwiper();

    const renderSwiper : any = (condition : boolean ) => (
        <>
            <div className="d-flex">
                <h2 className="heading ps-0">{heading}</h2>
                {  (condition) && (
                    <div className="ms-auto d-flex">
                        <button
                            className={ "image-swiper-button-prev prev-" + swiperClass}
                            onClick={() => swiper.slidePrev() }
                        >
                            
                            <i className="fa-solid fa-less-than"></i>
                        </button>
                        <button
                            className={"image-swiper-button-next next-" + swiperClass}
                            onClick={() => swiper?.slideNext()}
                        >
                            <i className="fa-solid fa-greater-than"></i>
                        </button>
                    </div>
                )}
            </div>
            <Swiper
                scrollbar={{
                    hide: false,
                }}
                spaceBetween={10}
                navigation={{
                    nextEl: '.next-' + swiperClass ,
                    prevEl: '.prev-'+swiperClass,
                    disabledClass: 'swiper-button-disabled',
                }}
                modules={[Navigation, Scrollbar]}
                slidesPerView={'auto'}
                onInit={(swipe) => {
                    swiper = swipe;
                }}
                
                className={`swiper-product text-white ${swiperClass}`}                
            >
                {clonedElements}
            </Swiper>
        </>
    )

    const clonedElements : any[] = items.map((ele : any,i : number) => (
        <SwiperSlide key={i} className="pb-4">                
                <div className='px-4 h-100'>
                    {React.cloneElement(children, ele)}
                </div>
        </SwiperSlide>
    ));
    return (
        <>
        {xlScreen ? renderSwiper((items.length !== 2 && items.length !== 1)) : renderSwiper((!smScreen && items.length !== 1 ))}
        </>
    );
};

export default ProductSwiper;
