import { Link } from 'react-router-dom';
export default function Footer() {
    return (
        <div className="bg-darker footer fw-semibold" id="footer">
            <div className="container">
                <div className="row gy-5 p-2 p-lg-0">
                    <div className="col-md-4 col-lg-3">
                        <div className="d-flex flex-md-column align-items-end align-items-md-start justify-content-between">
                            <div className="">
                                <img
                                    src="/images/footerLogo.svg"
                                    alt="swords"
                                    className="mb-3"
                                />
                                <p className="my-md-4 my-0">© 2022 Zogi Labs</p>
                            </div>
                            <div className="">
                                <a
                                    className="link-sm"
                                    href="https://account.zogilabs.io/privacy-policy"
                                >
                                    Privacy Policy
                                </a>
                                <a
                                    className="link-sm"
                                    href="https://account.zogilabs.io/terms-and-conditions"
                                >
                                    Terms & Conditions
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-8">
                        <div className="row justify-content-start">
                            <div className="col-6">
                                <h3 className="mb-4 heading-sm">Products</h3>

                                <a
                                    className="footer-link mb-1"
                                    href="http://www.bezogia.com/"
                                >
                                    Legends of Bezogia{' '}
                                </a>
                                {/* <Link className="footer-link mb-1" to="">
                                    Bull Market
                                </Link>
                                <Link className="footer-link mb-1" to="">
                                    Zogi Wallet
                                </Link>
                                <Link className="footer-link mb-1" to="">
                                    The Pyramid Scheme
                                </Link> */}
                                <a
                                    className="footer-link mb-1"
                                    href="https://account.zogilabs.io/"
                                    target="_blank" rel="noreferrer"
                                >
                                    Zogi Hub
                                </a>
                                <a
                                    className="footer-link mb-1"
                                    href="https://account.zogilabs.io/hub/staking"
                                    target="_blank" rel="noreferrer"
                                >
                                    Staking
                                </a>
                            </div>
                            <div className="col-6">
                                <h3 className="mb-4 heading-sm">About</h3>

                                <Link className="footer-link mb-1" to="/team">
                                    Team{' '}
                                </Link>
                                <a
                                    className="footer-link mb-1"
                                    href="https://medium.com/@ZogiLabs"
                                    target="_blank" rel="noreferrer"
                                >
                                    Blog
                                </a>
                                <a
                                    className="footer-link mb-1"
                                    href="https://docs.bezogia.com/"
                                    target="_blank" rel="noreferrer"
                                >
                                    Whitepaper
                                </a>
                                <a
                                    className="footer-link mb-1"
                                    href="https://www.linkedin.com/company/zogi-labs/jobs/"
                                    target="_blank" rel="noreferrer"
                                >
                                    Careers
                                </a>
                                <a
                                    className="footer-link mb-1"
                                    href="mailto: press@bezoge.com"
                                    target="_blank" rel="noreferrer"
                                >
                                    Press
                                </a>
                                <a
                                    className="footer-link mb-1"
                                    href="mailto: investordesk@bezoge.com"
                                    target="_blank" rel="noreferrer"
                                >
                                    Investors
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-lg-end  text-center">
                        <div className="">
                            <a
                                className="footer-link d-inline-block p-4"
                                href="mailto: hello@bezoge.com"
                            >
                                Support{' '}
                            </a>
                            <a
                                className="btn-green"
                                href="https://account.zogilabs.io/"
                            >
                                Zogi Hub
                            </a>
                        </div>
                        <div className="mt-4 footer-icons">
                            <a
                                className="footer-link d-inline-block"
                                href="https://twitter.com/zogilabs"
                                target="_blank"
                            >
                                <i className="fa-brands fa-2x ps-3 fa-twitter"></i>{' '}
                            </a>
                            <a
                                className="footer-link d-inline-block"
                                href="https://www.linkedin.com/company/zogi-labs/"
                                target="_blank"
                            >
                                <i className="fa-brands fa-2x ps-3 fa-linkedin-in"></i>{' '}
                            </a>
                            <a
                                className="footer-link d-inline-block"
                                href="https://discord.gg/peCmdzm4ew"
                                target="_blank"
                            >
                                <i className="fa-brands fa-2x ps-3 fa-discord"></i>{' '}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
