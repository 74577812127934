import ProductItem from './ProductItem/ProductItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';

import 'swiper/css';
import 'swiper/css/scrollbar';
import useMediaQuery from '../../hooks/useMediaQuery';

const products = [
    {
        imgSrc: 'games',
        heading: 'Games',
        detailsLink : 'http://www.bezogia.com/',
        linkContent : 'Learn More'
    },
    {
        imgSrc: 'marketplace',
        heading: 'Marketplace',
    },
    {
        imgSrc: 'wallet',
        heading: 'Wallet',
    },
    {
        imgSrc: 'infrastructure',
        heading: 'Infrastructure',
        detailsLink : 'https://account.zogilabs.io/',
        linkContent : "Learn More"
    },
];

export default function ProductsSection() {
    const smScreen = useMediaQuery('(max-width: 768px)');
    
    return (
        <div className="mb-5 py-3">
            

            <h2 className="heading text-center my-5">Products</h2>
            <div className="container">
                <div className="row g-4">
                { smScreen && <Swiper
                scrollbar={{
                    hide: false,
                }}
                spaceBetween={30}
                modules={[Scrollbar]}
                className="mySwiper text-white"
            >
                
                {products.map((product) => (
                        <SwiperSlide
                            key={product.heading}
                            className="pb-3"
                        >
                            <ProductItem {...product} />
                        </SwiperSlide>                       
                    ))}
            </Swiper>}

            {!smScreen && products.map((product) => ( <div
                            key={product.heading}
                            className="col-lg-3 col-md-6 px-4 px-md-3 px-lg-2 py-2"
                        >
                            <ProductItem {...product} />
                        </div>))  }
                </div>
            </div>
        </div>
    );
}
