import Hero from './../../sections/Home/Hero';
import MediaCard from './../../components/MediaCard/MediaCard';
import ZogiWay from '../../sections/Home/ZogiWay';
import ProductsSection from '../../sections/Home/ProductsSection';
import LegendsOfBezogia from '../../sections/Home/LegendsOfBezogia';
import Navbar from '../../components/Navbar/Navbar';


const experience = {
  imgSrc : 'images/swords.png',
  heading : "Ten Years Ahead",
  paragraphs :[ "With our elite squad of game developers, designers, and marketers, we're paving the way for the future of Web3 entertainment. We're not just playing games, we're making history...and having a blast doing it." ]
}

const notNoobs = {
  imgSrc : 'images/notNoobs.png',
  heading : "We're Not Noobs",
  paragraphs :[ "Zogi Labs' game designers have been 'playing the game' for their entire lives, and as a team, we boast over a century of gaming experience. So when we say we know a bit about crafting a quality gaming experience, we're not playing around.",
  "We're here to raise the bar for crypto gaming and leave the 'pixelated' past behind. The Legends of Bezogia is the future of the industry and a game-changer in the world of crypto gaming."
  ],
reverse : true
}

const cryptoGaming = {
  imgSrc : 'images/cryptoGaming.png',
  heading : "Building the Future of Crypto Gaming",
  paragraphs :[ "At Zogi Labs, our mission is to seamlessly blend blockchain technology and cutting-edge gaming mechanics to create a truly immersive digital realm - a place where gamers can escape into stunning otherworldly landscapes, live vicariously through their Bezogi avatars, and have a block-buster of a time in the lands of Bezogia.",
  "We're raising the bar for crypto-based games with breathtaking graphics, a rich storyline, quirky characters, and a level of fun that will keep you chained to your screen."
]
}

export default function Home() {
  return (
    <>
    <Navbar whiteLogo={false} />
    <div className='md-center sm-center'>
      <Hero />
      <div className="prpl-gradient"></div>
      <div className='green-gradient'></div>
      <MediaCard {...experience}/>
      <ZogiWay />
      <MediaCard {...cryptoGaming}/>
      <MediaCard {...notNoobs}/>



      <ProductsSection />

      <LegendsOfBezogia />
    </div>
    </>
  )
}
