import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import useMediaQuery from './../../hooks/useMediaQuery';
const HeaderHero: React.FC<any> = ({
    heading = 'The Dream Team',
    imgSrc = 'teampage/teamHero',
    desc = 'Description for the Zogi Labs team page',
    breadcumb = 'Team',
}) => {
    const lgScreen = useMediaQuery('(min-width: 1400px)');
    return (
        <>
            <div className="hero-page radius-bottom-60 bg-grediant-prpl overflow-hidden position-relative">
                <Navbar />
                <div className="container">
                    <div className="row text-center text-xxl-start">
                        <div className=" py-xxl-4">
                            {/* <div className="d-inline-block ">
                                <div className="page-breadcrumb bg-18 px-4 py-3 radius-10 d-flex align-items-center">
                                    <Link to="/" className="footer-link">
                                        Home
                                    </Link>
                                    <span className="mx-2 text-success">.</span>
                                    <span className="text-white text-opacity-50">
                                        {breadcumb}
                                    </span>
                                </div>
                            </div> */}
                        </div>
                        <div className="hero-section py-xxl-4">
                            <h1 className="main-heading py-xxl-5 mt-5">{heading}</h1>
                            <p className="text-lg py-5 my-5 py-xxl-0 my-xxl-4"></p>
                            <div className="hero-page-img">
                                {lgScreen ? (
                                    <img
                                        src={`/images/${imgSrc}.png`}
                                        className="w-100"
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        src={`/images/${imgSrc}MD.png`}
                                        className="w-100"
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HeaderHero;
