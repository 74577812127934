import './zogiwayCard.scss';


interface ZogiwayCardProps {
  bg : string ,
  imgSrc : string ,
  heading : string
}
export default function ZogiwayCard({bg , imgSrc , heading} : ZogiwayCardProps) {
  return (
    <div className="text-center zogiway position-relative">
        <div className={`zogiway-img ${bg}`}>
            <img src={`/images/features/${imgSrc}.svg`} alt="ownership" />
        </div>
        <h3 className="heading-sm">
            {heading}
        </h3>
    </div>
  )
}
