import ZogiwayCard from './ZogiwayCard/ZogiwayCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';

import 'swiper/css';
import 'swiper/css/scrollbar';
import useMediaQuery from '../../hooks/useMediaQuery';

const cards = [
    {
        bg: 'gradient-prpl',
        imgSrc: 'shield',
        heading: 'Ownership First',
    },
    {
        bg: 'gradient-green',
        imgSrc: 'user',
        heading: 'Crypto For The Masses',
    },
    {
        bg: 'gradient-orange',
        imgSrc: 'globe',
        heading: 'No Limits',
    },
    {
        bg: 'gradient-red',
        imgSrc: 'tech',
        heading: 'Tech That Works',
    },
];

export default function ZogiWay() {

    const smScreen = useMediaQuery('(max-width: 768px)');
    return (
        <div>
            <h2 className="heading text-center  my-5">The Zogi Way</h2>
            <div className="container pt-4">
                <div className="row gy-3">
                    {smScreen && (
                        <Swiper
                            scrollbar={{
                                hide: false,
                            }}
                            spaceBetween={40}
                            modules={[Scrollbar]}
                            className="mySwiper text-white"
                        >
                            {cards.map((card) => (
                                <SwiperSlide key={card.imgSrc}>
                                    <ZogiwayCard {...card} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}
                    { !smScreen &&  cards.map((card) => (
                        <div
                            key={card.imgSrc}
                            className="col-lg-3 col-md-6 px-md-3 px-4 px-lg-2"
                        >
                            <ZogiwayCard {...card} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
