
export default function SingleProduct({imgSrc , title , desc , dollar = false , comingSoon = false , link = "/#/product"} : any) {
    
    return (
        <>
            <a  className='products-link'  rel="noreferrer" href={link} target='_blank'>

            
                <div className="row product h-100   gy-2 radius-30 px-4 py-5 align-items-center">
                    <div className="col-12 col-md-4 text-center">
                        <img src={imgSrc} className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-md-7">
                        <h5 className="heading-sm">
                            {
                                dollar && <span style={{color : "#3FF0C9"}}>$</span>
                            }
                            {title}</h5>
                        <p className="text mb-0">{desc} <br />
                        {
                            comingSoon && <span style={{color : "#3FF0C9"}}>COMING 2023</span>
                        }
                        
                        </p>
                    </div>
                </div>
                </a>
        </>
    );
}
