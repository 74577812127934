import HeaderHero from '../Team/HeaderHero';
import ProductPageItem from './ProductPageItem';

const ourGames = [
    {
        imgSrc: '/images/Legends_of_Bezogia.png',
        title: 'Legends of Bezogia',
        desc: 'The future of crypto-based MMORPG gaming',
        gredient: '',
        link: 'https://www.bezogia.com/'
    },
];

const ourApplications = [
    {
        imgSrc: '/images/productsPage/ourApplication/bullMarket.svg',
        title: 'Zogi Hub',
        desc: `The all in one platform giving you access to all Zogi Labs products.`,
        gredient: '(225deg, #6A55FE 0%, #513CE9 100%)',
        link: "https://account.zogilabs.io/"
    },
];

const digitalCollectibles = [
    {
        imgSrc: '/images/productsPage/digital/Bezogi.svg',
        title: 'Bezogi',
        desc: `8 breeds of playable NFT 
        characters used in-game when 
        playing The Legends of Bezogia.`,
        gredient: '(225deg, #FF6F31 0%, #E34400 100%)',
        link: 'https://opensea.io/collection/bezogiv2'
    },
    {
        imgSrc: '/images/productsPage/digital/petzogi.svg',
        title: 'Petzogi',
        desc: `Fun and ferocious pets that
        accompany your Bezogi character
        in The Legends of Bezogia.`,
        gredient: '(225deg, #EC1B6C 0%, #D70053 100%)',
        link: 'https://opensea.io/collection/petzogis'
    },
];
const ourCryptoTokens = [
    {
        imgSrc: '/images/productsPage/crypto/mblk.svg',
        dollar: true,
        title: 'MBLK',
        desc: `An ERC20 utility token and the main in-game currency in Legends of Bezogia.`,
        comingSoon: false,
        gredient: '',
        link: 'https://mblk.bezogia.com/#/claim'
    },
];
const infrastructures = [
    {
        imgSrc: '/images/productsPage/infrastructure/staking.svg',
        title: 'Staking',
        desc: `Stake your BEZOGE or ZOGI 
        to earn rewards in MBLK.`,
        comingSoon: false,
        gredient: '',
        link: 'https://account.zogilabs.io/hub/staking'
    },
    {
        imgSrc: '/images/productsPage/infrastructure/location.svg',
        title: 'Land',
        desc: `Own your very own plot of land in Bezogia.`,
        comingSoon: true,
        gredient: '(225deg, #6A55FE 0%, #513CE9 100%)',
        // link : ''
    },
];

export default function Products() {

    return (
        <>
            <HeaderHero
                imgSrc="productsPage/productHero"
                heading="Products"
                desc="Description for the Zogi Labs products page"
                breadcumb="Products"
            />
            <div className="container-lg">
                <ProductPageItem swiperClass="swiper1" productsInfo={ourGames} heading={"Our Games"} />
                <ProductPageItem swiperClass="swiper2" productsInfo={ourApplications} heading={"Our Applications"} />
                <ProductPageItem swiperClass="swiper3" productsInfo={digitalCollectibles} heading={"Digital Collectibles"} />
                <ProductPageItem productsInfo={ourCryptoTokens} swiperClass="swiper4" heading={"Our Crypto Tokens"} />
                <ProductPageItem productsInfo={infrastructures} swiperClass="swiper5" heading={"Zogi Infrastructure"} />
            </div>
        </>
    );
}


