import { Link } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';

export default function Navbar({ whiteLogo = true }) {
    const smScreen = useMediaQuery('(max-width: 768px)');

    return (
        <nav className="navbar navbar-expand-lg p-4 bg-transparent navbar-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    {smScreen ? (
                        whiteLogo ? (
                            <img
                                src="/images/navbar/mainLogoWhiteSM.svg"
                                alt="swords"
                                className=""
                                width={47}
                            />
                        ) : (
                            <img
                                src="/images/footerLogo.svg"
                                alt="swords"
                                className=""
                                width={47}
                            />
                        )
                    ) : whiteLogo ? (
                        <img
                            src="/images/navbar/mainLogoWhite.svg"
                            alt="Logo"
                        />
                    ) : (
                        <img src="/images/navbar/logo.svg" alt="Logo" />
                    )}
                </Link>
                <div className="order-lg-last ms-auto d-flex gap-2 align-items-center nav-support fw-semibold">
                    <a
                        className="footer-link nav-link btn-support d-inline-block p-4"
                        href="mailto: hello@bezoge.com"
                    >
                        {smScreen ? (
                            <img
                                src="/images/navbar/message.svg"
                                className="w-100"
                                alt="nav link"
                            />
                        ) : (
                            'Support'
                        )}
                    </a>
                    <a
                        className="btn-green p-4 py-3"
                        href="https://account.zogilabs.io/"
                        target="_blank" rel="noreferrer"
                    >
                        {smScreen ? (
                            <img src="/images/navbar/user.svg" alt="nav link" />
                        ) : (
                            'Zogi Hub'
                        )}
                    </a>
                </div>

                <button
                    className="navbar-toggler ms-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className="collapse navbar-collapse text-center "
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav ms-4 me-auto mb-2 mb-lg-0">
                        <li className="nav-item mx-2">
                            <Link
                                className="nav-link text-white fw-semibold"
                                aria-current="page"
                                to="/product"
                            >
                                Products
                            </Link>
                        </li>
                        <li className="nav-item mx-2">
                            <Link
                                className="nav-link text-white fw-semibold"
                                to="/team"
                            >
                                Team
                            </Link>
                        </li>
                        <li className="nav-item mx-2">
                            <a
                                className="nav-link text-white fw-semibold"
                                href="https://medium.com/@ZogiLabs"
                                target="_blank" rel="noreferrer"
                            >
                                Blog
                            </a>
                        </li>
                        <li className="nav-item mx-2">
                            <a
                                className="nav-link text-white fw-semibold"
                                href="https://www.linkedin.com/company/zogi-labs/jobs/"
                                target="_blank" rel="noreferrer"
                            >
                                Careers
                            </a>
                        </li>
                        <li className="nav-item mx-2">
                            <a
                                className="nav-link text-white fw-semibold"
                                href="https://docs.bezogia.com/"
                                target="_blank" rel="noreferrer"
                            >
                                Whitepaper
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
