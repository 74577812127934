import React from 'react';
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import useMediaQuery from '../../hooks/useMediaQuery';
import ProductSwiper from './ProductSwiper';
import SingleProduct from './SingleProduct';



const ourGames = [
    {
        imgSrc: '/images/Legends_of_Bezogia.png',
        title: 'Legends of Bezogia',
        desc: 'The future of crypto-based MMORPG gaming',
        gredient: '',
    },
];


const ProductPageItem = ({productsInfo , heading , swiperClass} : any) => {



    return (
        <div className="row bg-12 radius-60 my-5 p-lg-5 p-3">
            <ProductSwiper  swiperClass={swiperClass} items={productsInfo} heading={heading}>
                <SingleProduct />
            </ProductSwiper>

                    {/* <div className="d-flex">
                        <h2 className="heading ps-0">{heading}</h2>
                        {!smScreen && (
                            <div className="ms-auto d-flex">
                                <button
                                    className="image-swiper-button-prev "
                                    onClick={() => swiper.slidePrev()}
                                >
                                    <i className="fa-solid fa-less-than"></i>
                                </button>
                                <button
                                    className="image-swiper-button-next "
                                    onClick={() => swiper.slideNext()}
                                >
                                    <i className="fa-solid fa-greater-than"></i>
                                </button>
                            </div>
                        )}
                    </div> */}

                    {
                        // <Swiper
                        //     scrollbar={{
                        //         hide: false,
                        //     }}
                        //     spaceBetween={50}
                        //     navigation={{
                        //         nextEl: '.image-swiper-button-next',
                        //         prevEl: '.image-swiper-button-prev',
                        //         disabledClass: 'swiper-button-disabled',
                        //     }}
                        //     modules={[Navigation, Scrollbar]}
                        //     centeredSlides={false}
                        //     slidesPerView={'auto'}

                        //     // onInit= {(swipe)=> {swiper = swipe}}
                        //     // grabCursor={true}
                        //     breakpoints={{
                        //         640: {
                        //             scrollbar: false,
                        //         },
                        //     }}
                        //     className="swiper-product text-white"
                        // >
                        //     {productsInfo?.map((product : any, i : any) => (
                        //         <SwiperSlide key={i} className="pb-4 pt-3">
                        //             <div className="row product   gy-2 radius-30 px-4 py-5 align-items-center">
                        //                 <div className="col-12 col-md-5 text-center">
                        //                     <img
                        //                         src={product.imgSrc}
                        //                         className="img-fluid"
                        //                         alt=""
                        //                     />
                        //                 </div>
                        //                 <div className="col-12 col-md-7">
                        //                     <h5 className="heading-sm">
                        //                         {product.title}
                        //                     </h5>
                        //                     <p className="text mb-0">
                        //                         {product.desc}
                        //                     </p>
                        //                 </div>
                        //             </div>
                        //         </SwiperSlide>
                        //     ))}
                        // </Swiper>
                    }
                </div>
    );
}

export default ProductPageItem;
