import React, { useRef } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { useSwiper } from 'swiper/react';
import { Scrollbar } from 'swiper';

import { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import useMediaQuery from '../../hooks/useMediaQuery';
export default function LegendsOfBezogia() {
    const swiper = useSwiper();
    const smScreen = useMediaQuery('(max-width: 640px)');

    const iframeRef: any = useRef();

    function handleCloseIframe() {
        iframeRef.current.contentWindow.postMessage(
            '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
            '*'
        );
    }

    return (
        <div className=" bezogia container-fluid my-3  py-5">
            <div className="row gy-3 p-md-5 pl-xxl-none">
                {/* <div className="col-xl-3 px-5 px-xl-2">
                    <div className=" bezogia-card px-5 py-5 text-center">
                        <div className="d-md-flex gap-1 flex-wrap justify-content-evenly mx-auto d-xl-block">
                            <div className="w-100">
                                <img
                                    src="/images/Legends_of_Bezogia.svg"
                                    className="mb-4"
                                    alt=""
                                />
                            </div>
                            <div className="bezogia-item mx-4 py-1">
                                <h4 className="fw-bold mb-1 h3">
                                    100,000<span className="text-green">+</span>
                                </h4>
                                <p>Community Members</p>
                            </div>
                            <div className="bezogia-item mx-4 py-1">
                                <h4 className="fw-bold mb-1 h3">
                                    31,000<span className="text-green">+</span>
                                </h4>
                                <p>Token Holders</p>
                            </div>
                            <div className="bezogia-item mx-4 py-1">
                                <h4 className="fw-bold mb-1 h3">
                                    <span className="text-green">$</span>25.6M
                                </h4>
                                <p>Market Cap (MC)</p>
                            </div>
                            <div className="bezogia-item mx-4 py-1">
                                <h4 className="fw-bold mb-1 h3">
                                    6000<span className="text-green">+</span>
                                </h4>
                                <p>Beta Players</p>
                            </div>
                            <div className="bezogia-item mx-4 py-1">
                                <h4 className="fw-bold mb-1 h3">
                                    <span className="text-green">$</span>58M
                                </h4>
                                <p>Fully Diluted (MC)</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-9 d-flex p-sm-4 gap-4 flex-column justify-content-between pt-2 ps-3">
                    <p className="lead lege">
                        The Legends of Bezogia is a cross-platform crypto-based
                        MMORPG that takes the crypto gaming industry to new
                        heights, quite literally! With a vast open-world, deep
                        lore, an engaging storyline, and cutting-edge gameplay,
                        it's an epic adventure that you won't want to miss. So
                        join us as we level up the world of crypto gaming, one
                        quest at a time.
                    </p>
                    {/* ******************************************************************************** */}
                    {/* <button type="button" onClick={handleOpenIframe} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >

                        Launch demo modal
                    </button> */}

                    <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl ">
                            <div className="modal-content">
                                <div className="modal-body bg-14 p-0">

                                    <div className="ratio ratio-16x9 ">
                                        <iframe
                                            ref={iframeRef}
                                            src="https://www.youtube.com/embed/3Q00dfkatho?autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <i
                                        onClick={handleCloseIframe}
                                        className="closeIframeBtn fa-solid fa-xmark fa-2x  color-green-embed"
                                        data-bs-dismiss="modal"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ********************************************************************************************************************* */}

                    <div className="">
                        {/* {smScreen ? (
                            <Swiper
                                scrollbar={{
                                    hide: false,
                                }}
                                initialSlide={1}
                                spaceBetween={40}
                                modules={[Scrollbar]}
                                className="bezogia-slider"
                            >
                                
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-2.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <figcaption className='h-100'>
                                    <img
                                        src="/images/legends/slide-1.png"
                                        className="h-100"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                        alt=""
                                    />
                                    <div className="embed-overlay">
                                        <div className="">

                                        <img src="/embededVideo.png" 
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"

                                        alt="" />
                                        </div>
                                    </div>
                                    </figcaption>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-3.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-4.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-5.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-6.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-7.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-8.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="/images/legends/slide-9.png"
                                        className="mb-4"
                                        alt=""
                                    />
                                </SwiperSlide>
                            </Swiper>
                        ) : ( */}
                        <Swiper
                            navigation={{
                                nextEl: '.image-swiper-button-next',
                                prevEl: '.image-swiper-button-prev',
                                disabledClass: 'swiper-button-disabled',
                            }}
                            scrollbar={{ hide: false }}
                            modules={[Navigation, Scrollbar]}
                            slidesPerView={'auto'}
                            // grabCursor={true}
                            breakpoints={{
                                640: {
                                    centeredSlides: true,
                                    initialSlide: 1,
                                    scrollbar: {},
                                },
                            }}
                            spaceBetween={45}
                            className="bezogia-slider legends-scroll"
                        >
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-2.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <figcaption className="h-100">
                                    <img
                                        src="/images/legends/slide-1.png"
                                        className="h-100"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        alt=""
                                    />
                                    <div className="embed-overlay">
                                        <div className="">
                                            <img
                                                src="/embededVideo.svg"
                                                data-bs-toggle="modal"
                                                data-bs-target="#staticBackdrop"
                                                className="cursor-pointer"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </figcaption>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-3.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-4.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-5.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-6.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-7.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-8.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    src="/images/legends/slide-9.png"
                                    className="mb-4"
                                    alt=""
                                />
                            </SwiperSlide>
                            {!smScreen && (
                                <div className="">
                                    <button
                                        className="image-swiper-button-prev btn-swiper"
                                        onClick={() => swiper.slidePrev()}
                                    >
                                        <i className="fa-solid fa-less-than"></i>
                                    </button>
                                    <button
                                        className="image-swiper-button-next btn-swiper"
                                        onClick={() => swiper.slideNext()}
                                    >
                                        <i className="fa-solid fa-greater-than"></i>
                                    </button>
                                </div>
                            )}
                        </Swiper>
                        {/* )} */}
                    </div>

                    <div className="d-flex gap-4 flex-wrap flex-xl-nowrap align-items-center mt-3">
                        <p className="lege2">
                            The game allows Web2 players from all around the
                            world to jump right in, without any prior knowledge
                            of cryptocurrencies and enjoy the game with zero
                            barriers to entry. The Legends of Bezogia is the
                            first crypto game for non crypto people.
                        </p>
                        <div className="mx-auto d-flex align-items-center">
                            <a
                                href="https://discord.gg/peCmdzm4ew"
                                target="_blank"
                                className="btn discordBtn py-3 px-lg-4"
                            >
                                Join Discord
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
